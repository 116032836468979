import React, {useEffect, useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form';
import {pathFor} from '../../../constants/apiPaths'
import Request from '../../../services/Request'

function PubmaticSegmentSelector(props) {
    const {setPayload, currentValue, setMessageQueue} = props
    const [selected, setSelected] = useState(currentValue && "segment" in currentValue ? currentValue.segment : "Select segment")
    const [segments, setSegments] = useState([])

    useEffect(async () => {
        setSelected(currentValue && "segment" in currentValue ? `${currentValue.providerAudienceId} - ${currentValue.segment}` : "Select segment")
    }, [currentValue])

    useEffect(async () => {
        try {
            const {statusCode, jsonResponse} = await Request(pathFor.customsegmentrules, {}, {}, "GET");
            if (statusCode === 200) {
                const {segments} = jsonResponse
                // setSegments(segments.map(o => `${o.providerAudienceId} - ${o.name}`))
                setSegments(segments)
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch segments."}])
            }
        } catch (err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }, [])

    const CustomMenu = React.forwardRef(
        ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-75"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );
    return (
        <Dropdown onSelect={value => {
            let segment = segments.find(o => o._id === value)
            if (!segment) {
                return
            }
            setSelected(`${segment.providerAudienceId} - ${segment.name}`)
            setPayload({segment_id: value, segment: segment.name, providerAudienceId: segment.providerAudienceId})
        }}>
            <Dropdown.Toggle id="dropdown-custom-components" variant="white"
                             className="w-100 d-flex justify-content-between align-items-center overflow-hidden">
                {selected}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} className="w-100 border--light">
                {segments.map((segment, idx) => (
                    <Dropdown.Item key={idx}
                                   eventKey={segment._id}>{`${segment.providerAudienceId} - ${segment.name}`}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default PubmaticSegmentSelector
