import React, { useEffect } from 'react'
import MoreActions from './MoreActions'
import { displayText } from './utils/generateTableCell'
import TableCheckBox from './TableCheckBox'
import './styles.css'
import { isBoolean } from 'underscore'


function TableBody(props) {
    const { tableData, setMessageQueue, selectedItems, selectAll, setSelectedItems, setHeaderCheckboxActive, setSelectAll, actionClicked } = props
    const { tableHead, tableBody } = tableData
    const rowIds = tableBody.map(item => item["_id"])

    useEffect(() => {
        isBoolean(selectAll) && setSelectedItems(selectAll ? [ ...rowIds.filter(item => !selectedItems.includes(item)), ...selectedItems] : selectedItems.filter(item => !rowIds.includes(item)))
    }, [selectAll])

    useEffect(() => {
        setHeaderCheckboxActive(selectedItems && selectedItems.length && rowIds.every(item => selectedItems.includes(item)))
    }, [selectedItems, tableBody])

    const canShowMoreActions = function (actions, row) {
        return actions.some(action => {
            if (action.dependentOn && action.dependentOnValues && !action.dependentOnValues.includes(row[action.dependentOn])) return false
            else if (action.dependentOn && !row[action.dependentOn]) return false
            else if (action.noDisplayIf && action.noDisplayIf.some(item => item === row[action.dependentOn])) return false
            else if (!action.noDisplayIf) return true
            return true
        })
    }


    return (
        <>
            {
                tableBody && tableBody.length ? (
                    tableBody.map((tRow, idx) => (
                        <tr key={ idx }>
                            {
                                tableHead.filter(i => !i.hidden).map((tHead, idy) => (
                                    <td key={ idy }
                                        className={ tHead.colored && `text--${tRow.color}`}
                                    >
                                        <span>
                                            { tHead.actions ? (
                                                canShowMoreActions(tHead.actions, tRow) &&
                                                <MoreActions actions={tHead.actions} row={tRow}
                                                             setMessageQueue={setMessageQueue} actionClicked={actionClicked}/>
                                                ) : (
                                                    <span>
                                                        {
                                                            tHead.checkBox && (
                                                                <TableCheckBox setSelectedItems={ setSelectedItems } selectedItems={ selectedItems } rowId={ tRow["_id"] } setSelectAll={ setSelectAll } />
                                                            )
                                                        }
                                                        {
                                                            displayText(tRow, tHead)
                                                        }
                                                    </span>
                                                )
                                            }
                                        </span>
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                ) : (
                    <tr className="bg--secondary text-center">
                        <td colspan={ tableHead.length + 1 } className="py-3 rounded">
                            No data to display
                        </td>
                    </tr>
                )

            }
        </>
    )
}

export default TableBody
