import React, {useEffect, useState} from 'react'
import DateRangesPicker from '../../../components/DateRangesPicker'
import {DataTable} from '../../../components/Table'
import {pathFor} from '../../../constants/apiPaths'
import Request from '../../../services/Request'
import {pubmaticExportJobsHead} from '../../../constants/tableConstants'
import PubmaticSegmentSelector from "./PubmaticSegmentSelector";

function PubmaticReports(props) {
    const {currentState, setCurrentState, setMessageQueue} = props
    const [dateRanges, setDateRanges] = useState(currentState ? currentState.payload : null)
    const [segment, setSegment] = useState(currentState ? currentState.segment : null)
    const [exportJobs, setExportJobs] = useState(currentState ? currentState.exportJobs : null)

    const apiCall = async () => {
        setMessageQueue([])
        try {
            console.log(pathFor.createPubMaticExportJob)
            const {statusCode, jsonResponse} = await Request(pathFor.createPubMaticExportJob, {
                "segment_name": segment['segment'],
                "segment_id": segment['segment_id'],
                "start_date": dateRanges['date-start'],
                "end_date": dateRanges['date-end'],
            })
            if (statusCode === 201) {
                const {message} = jsonResponse
                // setDateRanges(null)
                setSegment(null)
                await fetchExportJobs()
                setMessageQueue([{"success": message}])
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch data."}])
            }
        } catch (error) {
            console.error(error)
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }

    const fetchExportJobs = async () => {
        try {
            const {statusCode, jsonResponse} = await Request(pathFor.pubmaticExportJobs, {}, {}, "GET")
            if (statusCode === 200) {
                const {export_jobs} = jsonResponse
                setExportJobs(export_jobs)
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch data."}])
            }
        } catch (err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }

    const downloadDatasetFiles = (files) => {
        if (files && files.length > 0) {
            files.forEach((fileUrl,inx) => {
                setTimeout(() => {
                    const link = document.createElement("a");
                    link.href = fileUrl;
                    link.download = fileUrl.split("/").pop(); // Extract file name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }, inx*1000);
            });
        }
    }

    const actionItemClicked = async (action, row) => {
        if (action.key === "cancel") {
            await fetchExportJobs()
        } else if (action.key === "download") {
            downloadDatasetFiles(row['s3_public_url'])
        }
    }

    useEffect(async () => {
        await fetchExportJobs()
    }, [])


    return (
        <div>
            <div className="mb-3 ">
                <span className="text-muted d-block mb-3">Date Range</span>
                <div className="d-flex">
                    <span className="mr-2 col-3 p-0 border--light rounded">
                        <DateRangesPicker setPayload={setDateRanges} currentValue={dateRanges}/>
                    </span>
                    <span className="mr-2 col-4 p-0 border--light rounded">
                        <PubmaticSegmentSelector setPayload={setSegment} currentValue={segment}
                                        setMessageQueue={setMessageQueue}/>
                    </span>
                    <button
                        type="submit"
                        className={`btn px-3 btn-${!!dateRanges && !!segment ? "primary" : "disabled"} border-0`}
                        onClick={apiCall}
                        disabled={!dateRanges || !segment}
                    >
                        <span>Export</span>
                    </button>
                </div>
            </div>
            <DataTable tableData={{tableHead: pubmaticExportJobsHead, tableBody: exportJobs}} infoHead={true}
                       actionClicked={actionItemClicked}
                       setMessageQueue={setMessageQueue}/>
        </div>
    )
}

export default PubmaticReports



