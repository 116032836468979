import React, {useState} from 'react'
import {Link, useRouteMatch} from 'react-router-dom'
import Request from '../../services/Request'
import {ReactComponent as KebabIcon} from './assets/KebabIcon.svg'


function MoreActions(props) {
    const {actions, row, setMessageQueue, actionClicked} = props
    const [showMenu, setShowMenu] = useState(false)
    const match = useRouteMatch()

    const handleClick = async (action) => {

        setMessageQueue([])
        if (action.path) {
            try {
                const {jsonResponse} = await Request(`${action.path}/${row['_id']}`)
                setMessageQueue([jsonResponse])
            } catch {
                setMessageQueue([{'error': "Server error. Try again after sometime."}])
            }
        }
        actionClicked && actionClicked(action, row)
    }


    return (
        <div onClick={() => setShowMenu(!showMenu)} className="d-flex justify-content-end">
            <span className="kebab-icon">
                <KebabIcon/>
            </span>
            <div className={showMenu ? "more-actions" : "d-none"}>
                {
                    actions.map((action, idx) => {
                        if (action.dependentOn && action.dependentOnValues && !action.dependentOnValues.includes(row[action.dependentOn])) return null
                        else if (action.dependentOn && !row[action.dependentOn]) return null
                        else if (action.noDisplayIf && action.noDisplayIf.some(item => item === row[action.dependentOn])) return null
                        else if (!action.noDisplayIf) return (
                            <div>
                                <Link to={`${match.path}${action.path}/${row['_id']}`}
                                      className="d-block action">
                                    {action.text}
                                </Link>
                            </div>
                        )
                        else return (
                                <div key={idx} onClick={() => handleClick(action)} className="action">
                                    {action.text}
                                </div>
                            )
                    })
                }
            </div>
        </div>
    )
}

export default MoreActions
